import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          The Game
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          You lose
        </a>
      </header>
<script async defer src="https://www.tst2.bookwana.com/widget.js"></script>

<div className="tryb-widget" data-type="landingPageEmbed" data-eid="439575"></div>
    </div>
  );
}

export default App;
